import React, { useEffect, useState } from 'react';
import './App.css';
import LoadingOverlay from './LoadingOverlay';
import logo from './logo.svg';

function App() {
  const [iframeSrc, setIframeSrc] = useState('');
  const [loading, setLoading] = useState(false); // Initially set to false
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    // Function to get URL parameters
    const getUrlParams = () => {
      const params = new URLSearchParams(window.location.search);
      return params.toString();
    };

    // Function to get the hostname
    const getHostname = () => {
      const { hostname } = window.location;
      const hostStripped = hostname.split('.')[0];
      return hostStripped;
    };

    // Get URL parameters and hostname
    const params = getUrlParams();
    const hostname = getHostname();

    // Update iframe src with hostname and parameters
    const updatedSrc = params
      ? `https://embed.archilabs.ai?${params}&reportedHost=${hostname}`
      : `https://embed.archilabs.ai?reportedHost=${hostname}`;
    
    setIframeSrc(updatedSrc);

    // Listen for messages from the iframe
    const handleMessage = (event) => {
      if (event.data === 'WEBRTCLOADING') {
        setLoading(true);

        // Clear existing timeout if any
        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        // Set a new timeout to hide loading overlay after 10 seconds
        const id = setTimeout(() => {
          setLoading(false);
        }, 10000);
        setTimeoutId(id);
      } else if (event.data === 'WEBRTCLOADED') {
        setLoading(false);

        // Clear timeout if loaded successfully
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  return (
    <div className="App">
      {loading && <LoadingOverlay />}
      {iframeSrc && (
        <iframe
          width="100%"
          height="100%"
          src={iframeSrc}
          frameBorder="0"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
          allow="fullscreen"
        ></iframe>
      )}
    </div>
  );
}

export default App;